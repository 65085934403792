import {createApp} from 'vue';
import App from './App.vue';
import router from "./router";
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import 'element-plus/theme-chalk/display.css';
import * as ElementPlusIconsVue from '@element-plus/icons-vue';
import 'wowjs/css/libs/animate.css';
import {WOW} from 'wowjs';
// 动画 animate.css
require('animate.css/animate.min.css');

const app = createApp(App);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
app.use(router).use(ElementPlus).mount('#app');
app.config.globalProperties.$wow = new WOW({
    boxClass: 'wow', // default
    animateClass: 'animated', // default
    offset: 150, // default
    mobile: true, // default
    live: false,

    // live为true时，控制台会提示：MutationObserver is not supported by your browser. & WOW.js cannot detect dom mutations, please call .sync() after loading new content.

    callback: function (box) {
        console.log("WOW: animating <" + box.tagName.toLowerCase() + ">")
    }
})

