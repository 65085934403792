import {createRouter, createWebHashHistory} from "vue-router";

const routes = [
    {
        path: "/",
        name: "home",
        component: () => import("../views/Home.vue"),
        meta: {
            isHideFooter: true,
        },
    },
    {
        path: "/publishers",
        name: "publishers",
        component: () => import("../views/Publishers.vue"),
        meta: {
            isHideFooter: true,
        },
    },
    {
        path: "/buyers",
        name: "buyers",
        component: () => import("../views/Buyers.vue"),
        meta: {
            isHideFooter: true,
        },
    },
    {
        path: "/advertisers",
        name: "advertisers",
        component: () => import("../views/Advertisers.vue"),
        meta: {
            isHideFooter: true,
        },
    },
    {
        path: "/sdk",
        name: "sdk",
        component: () => import("../views/Sdk.vue"),
        meta: {
            isHideFooter: true,
        },
    },
    {
        path: "/aboutus",
        name: "aboutus",
        component: () => import("../views/AboutUs.vue"),
    },
    {
        path: "/privacy-policy",
        name: "privacy",
        component: () => import("../views/Privacy-policy"),
    },
    {
        path: "/contact",
        name: "contact",
        component: () => import("../views/Contactus"),
    },
    {
        path: "/leader",
        name: "leader",
        component: () => import("../views/Leadership"),
    },
    {
        path: "/contract",
        name: "contract",
        component: () => import("../views/Contract.vue"),
    },
    {
        path: "/contract/insertion-order",
        name: "InsertionOrder",
        component: () => import("../views/InsertionOrder.vue"),
    },
    {
        path: "/contract/advertising-standards-for-billowlink-inventory",
        name: "Inventory",
        component: () => import("../views/Inventory.vue"),
    },
];
const router = createRouter({
    history: createWebHashHistory(),
    routes,
    //滚动行为:当路由进行切换跳转的时候，可以设置滚动条的位置
    scrollBehavior() {
        //设置top  left
        return {top: 0};
    },
});

export default router;
